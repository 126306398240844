<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar">
    <!--begin: Language bar -->
    <div class="topbar-item">
      <!-- <b-dropdown
        size="sm"
        variant="link"
        toggle-class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 text-decoration-none"
        no-caret
        right
        no-flip
      >
        <template v-slot:button-content>
          <img
            class="h-20px w-20px rounded-sm"
            :src="languageFlag || getLanguageFlag"
            alt=""
          />
        </template>
        <b-dropdown-text tag="div" class="min-w-md-175px">
          <KTDropdownLanguage
            v-on:language-changed="onLanguageChanged"
          ></KTDropdownLanguage>
        </b-dropdown-text>
      </b-dropdown> -->

      <b-dropdown size="sm" variant="button" no-caret right no-flip>
        <template v-slot:button-content>
          <div
            class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
            id="kt_quick_user_toggle"
          >
            <span
              class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
            >
              Hi,
            </span>
            <span
              class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
            >
              {{ currentUser.username | capitalize }}
            </span>
            <span class="symbol symbol-35 symbol-light-success">
              <img v-if="false" alt="Pic" :src="picture" />
              <span
                v-if="true"
                class="symbol-label font-size-h5 font-weight-bold"
              >
                {{ currentUser.username[0] | capitalize }}
              </span>
            </span>
          </div>
        </template>
        <div class="navi navi-hover min-w-md-175px">
          <b-dropdown-text tag="div" class="navi-item">
            <a href="#" class="navi-link" @click.prevent="logout">
              <span class="navi-icon">
                <i class="flaticon-logout"></i>
              </span>
              <span class="navi-text">Sign Out</span>
            </a>
          </b-dropdown-text>
        </div>
      </b-dropdown>
    </div>
    <!--end: Language bar -->

    <!--begin: User Bar -->
    <!-- <KTQuickUser></KTQuickUser> -->
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
// import KTDropdownLanguage from "@/view/layout/extras/dropdown/DropdownLanguage.vue"
// import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue"
import i18nService from "@/core/services/i18n.service.js"
import { mapGetters } from "vuex"
import { LOGOUT } from "@/core/services/store/auth.module"

export default {
  name: "KTTopbar",
  data() {
    return {
      languageFlag: "",
      languages: i18nService.languages,
    }
  },
  components: {
    // KTDropdownLanguage,
    // KTQuickUser,
  },
  methods: {
    onLanguageChanged() {
      this.languageFlag = this.languages.find((val) => {
        return val.lang === i18nService.getActiveLanguage()
      }).flag
    },
    logout() {
      this.$store.dispatch(LOGOUT)
      this.$router.replace({ name: "login" })
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
    getLanguageFlag() {
      return this.onLanguageChanged()
    },
  },
}
</script>
